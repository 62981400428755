import React from 'react';
import { SynagogueList } from '../components/schedule/SynagogueList';
import { PrayerTimeFilter } from '../components/schedule/PrayerTimeFilter';
import { useScheduleFilters } from '../hooks/useScheduleFilters';

export function Schedule() {
  const { filters, updateFilters, filteredSynagogues } = useScheduleFilters();

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">Horaires des Prières</h1>
      
      <PrayerTimeFilter filters={filters} onFilterChange={updateFilters} />
      
      <div className="mt-6">
        <SynagogueList synagogues={filteredSynagogues} />
      </div>
    </div>
  );
}