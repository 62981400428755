import React from 'react';
import { useStore } from '../store/useStore';
import { synagogues } from '../data/synagogues';
import { SynagogueList } from '../components/schedule/SynagogueList';

export function Favorites() {
  const { user } = useStore();
  const favoriteSynagogues = synagogues.filter((synagogue) =>
    user?.favorites.includes(synagogue.id)
  );

  if (favoriteSynagogues.length === 0) {
    return (
      <div className="max-w-4xl mx-auto text-center py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Mes Favoris</h1>
        <p className="text-gray-600">
          Vous n'avez pas encore de synagogues favorites. Parcourez la liste des
          synagogues et cliquez sur le cœur pour les ajouter à vos favoris.
        </p>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">Mes Favoris</h1>
      <SynagogueList synagogues={favoriteSynagogues} />
    </div>
  );
}