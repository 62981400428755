import React from 'react';

export function Logo() {
  return (
    <div className="flex items-center">
      <span className="text-2xl font-bold bg-gradient-to-r from-blue-600 via-indigo-600 to-blue-600 bg-clip-text text-transparent bg-size-200 animate-gradient">
        Tefilati
      </span>
    </div>
  );
}