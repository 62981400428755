import { useState, useMemo } from 'react';
import { synagogues } from '../data/synagogues';
import { useGeolocation } from './useGeolocation';
import { calculateDistance } from '../utils/distance';
import { getNextPrayerTime } from '../utils/time';

export interface ScheduleFilters {
  prayer: 'all' | 'shacharit' | 'mincha' | 'arvit';
  sortBy: 'distance' | 'time';
  showOnlyAccessible: boolean;
  showNextOnly: boolean;
}

export function useScheduleFilters() {
  const [filters, setFilters] = useState<ScheduleFilters>({
    prayer: 'all',
    sortBy: 'distance',
    showOnlyAccessible: false,
    showNextOnly: false,
  });

  const { coordinates } = useGeolocation();

  const filteredSynagogues = useMemo(() => {
    let filtered = [...synagogues];

    // Filter by accessibility
    if (filters.showOnlyAccessible) {
      filtered = filtered.filter((synagogue) => !synagogue.hasAccessCode);
    }

    // Filter by prayer type
    if (filters.prayer !== 'all') {
      filtered = filtered.filter((synagogue) =>
        synagogue.prayerTimes[filters.prayer].length > 0
      );
    }

    // Filter by next prayer only
    if (filters.showNextOnly) {
      filtered = filtered.filter((synagogue) => {
        const nextPrayer = getNextPrayerTime(synagogue.prayerTimes);
        return nextPrayer !== null;
      });
    }

    // Sort by distance or next prayer time
    if (filters.sortBy === 'distance' && coordinates) {
      filtered.sort((a, b) => {
        const distanceA = calculateDistance(
          coordinates.lat,
          coordinates.lng,
          a.coordinates.lat,
          a.coordinates.lng
        );
        const distanceB = calculateDistance(
          coordinates.lat,
          coordinates.lng,
          b.coordinates.lat,
          b.coordinates.lng
        );
        return distanceA - distanceB;
      });
    } else if (filters.sortBy === 'time') {
      filtered.sort((a, b) => {
        const nextPrayerA = getNextPrayerTime(a.prayerTimes);
        const nextPrayerB = getNextPrayerTime(b.prayerTimes);
        
        if (!nextPrayerA) return 1;
        if (!nextPrayerB) return -1;
        
        return nextPrayerA.remainingTime - nextPrayerB.remainingTime;
      });
    }

    return filtered;
  }, [filters, coordinates]);

  return {
    filters,
    updateFilters: setFilters,
    filteredSynagogues,
  };
}