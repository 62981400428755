import React from 'react';
import { Bell, Mail, Clock, AlertCircle } from 'lucide-react';
import { useStore } from '../store/useStore';
import { useNotifications } from '../hooks/useNotifications';

export function Notifications() {
  const { user, updateNotificationPreferences } = useStore();
  const { error } = useNotifications();
  const [formState, setFormState] = React.useState({
    email: user?.notificationPreferences.email || '',
    reminderMinutes: user?.notificationPreferences.reminderMinutes || 30,
  });

  const handleSubmit = React.useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    await updateNotificationPreferences({
      ...user.notificationPreferences,
      email: formState.email || undefined,
      reminderMinutes: formState.reminderMinutes,
    });
  }, [user, formState, updateNotificationPreferences]);

  const togglePrayer = React.useCallback((prayer: 'shacharit' | 'mincha' | 'arvit') => {
    if (!user) return;

    const prayers = user.notificationPreferences.prayers.includes(prayer)
      ? user.notificationPreferences.prayers.filter((p) => p !== prayer)
      : [...user.notificationPreferences.prayers, prayer];

    updateNotificationPreferences({
      ...user.notificationPreferences,
      prayers,
    });
  }, [user, updateNotificationPreferences]);

  // Update form state when user preferences change
  React.useEffect(() => {
    if (user) {
      setFormState({
        email: user.notificationPreferences.email || '',
        reminderMinutes: user.notificationPreferences.reminderMinutes,
      });
    }
  }, [user]);

  return (
    <div className="max-w-2xl mx-auto">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">Notifications</h1>

      {error && (
        <div className="mb-6 p-4 bg-yellow-50 rounded-lg flex items-start space-x-3">
          <AlertCircle className="w-5 h-5 text-yellow-500 flex-shrink-0 mt-0.5" />
          <div className="text-yellow-800">
            <p className="font-medium">Note importante</p>
            <p>{error}</p>
            <p className="mt-2">Les notifications par email restent disponibles.</p>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <Bell className="w-5 h-5 mr-2 text-blue-600" />
            Offices à notifier
          </h2>
          <div className="space-y-3">
            {(['shacharit', 'mincha', 'arvit'] as const).map((prayer) => (
              <label
                key={prayer}
                className="flex items-center space-x-3 text-gray-700"
              >
                <input
                  type="checkbox"
                  checked={user?.notificationPreferences.prayers.includes(prayer)}
                  onChange={() => togglePrayer(prayer)}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="capitalize">
                  {prayer === 'shacharit' ? 'Chaharit' : prayer}
                </span>
              </label>
            ))}
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <Clock className="w-5 h-5 mr-2 text-blue-600" />
            Délai de rappel
          </h2>
          <select
            value={formState.reminderMinutes}
            onChange={(e) => setFormState(prev => ({
              ...prev,
              reminderMinutes: Number(e.target.value)
            }))}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value={15}>15 minutes avant</option>
            <option value={30}>30 minutes avant</option>
            <option value={60}>1 heure avant</option>
            <option value={120}>2 heures avant</option>
          </select>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <Mail className="w-5 h-5 mr-2 text-blue-600" />
            Notifications par email
          </h2>
          <input
            type="email"
            value={formState.email}
            onChange={(e) => setFormState(prev => ({
              ...prev,
              email: e.target.value
            }))}
            placeholder="votre@email.com"
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Enregistrer les préférences
          </button>
        </div>
      </form>
    </div>
  );
}