import React from 'react';
import { Clock, MapPin } from 'lucide-react';
import type { ScheduleFilters } from '../../hooks/useScheduleFilters';

interface PrayerTimeFilterProps {
  filters: ScheduleFilters;
  onFilterChange: (filters: ScheduleFilters) => void;
}

export function PrayerTimeFilter({ filters, onFilterChange }: PrayerTimeFilterProps) {
  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <div className="grid md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Type de prière
          </label>
          <select
            value={filters.prayer}
            onChange={(e) =>
              onFilterChange({ ...filters, prayer: e.target.value as any })
            }
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="all">Toutes les prières</option>
            <option value="shacharit">Chaharit</option>
            <option value="mincha">Minha</option>
            <option value="arvit">Arvit</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Trier par
          </label>
          <select
            value={filters.sortBy}
            onChange={(e) =>
              onFilterChange({ ...filters, sortBy: e.target.value as any })
            }
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="distance">Distance</option>
            <option value="time">Prochain office</option>
          </select>
        </div>
      </div>

      <div className="mt-4 flex items-center space-x-4">
        <button
          onClick={() =>
            onFilterChange({ ...filters, showOnlyAccessible: !filters.showOnlyAccessible })
          }
          className={`flex items-center px-3 py-1 rounded-full text-sm ${
            filters.showOnlyAccessible
              ? 'bg-blue-100 text-blue-800'
              : 'bg-gray-100 text-gray-600'
          }`}
        >
          <MapPin className="w-4 h-4 mr-1" />
          Accès libre uniquement
        </button>

        <button
          onClick={() =>
            onFilterChange({ ...filters, showNextOnly: !filters.showNextOnly })
          }
          className={`flex items-center px-3 py-1 rounded-full text-sm ${
            filters.showNextOnly
              ? 'bg-blue-100 text-blue-800'
              : 'bg-gray-100 text-gray-600'
          }`}
        >
          <Clock className="w-4 h-4 mr-1" />
          Prochains offices uniquement
        </button>
      </div>
    </div>
  );
}