import React from 'react';
import { Map as MapComponent } from '../components/Map';

export function Map() {
  return (
    <div className="max-w-6xl mx-auto">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">Carte des Synagogues</h1>
      <div className="card p-4">
        <MapComponent />
      </div>
    </div>
  );
}