import React from 'react';
import type { Synagogue } from '../../types';

interface PrayerTimesProps {
  times: Synagogue['prayerTimes'];
}

export function PrayerTimes({ times }: PrayerTimesProps) {
  return (
    <div className="grid grid-cols-3 gap-4">
      <div>
        <h3 className="text-sm font-medium text-gray-900 mb-2">Chaharit</h3>
        <ul className="space-y-1">
          {times.shacharit.map((time, index) => (
            <li key={index} className="text-gray-600">
              {time}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h3 className="text-sm font-medium text-gray-900 mb-2">Minha</h3>
        <ul className="space-y-1">
          {times.mincha.map((time, index) => (
            <li key={index} className="text-gray-600">
              {time}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h3 className="text-sm font-medium text-gray-900 mb-2">Arvit</h3>
        <ul className="space-y-1">
          {times.arvit.map((time, index) => (
            <li key={index} className="text-gray-600">
              {time}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}