import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { synagogues } from '../data/synagogues';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';

// Fix for default marker icon
const icon = new Icon({
  iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png',
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

export function Map() {
  return (
    <MapContainer
      center={[43.2965, 5.3698]} // Marseille center
      zoom={13}
      className="h-[600px] w-full rounded-lg shadow-md"
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {synagogues.map((synagogue) => (
        <Marker
          key={synagogue.id}
          position={[synagogue.coordinates.lat, synagogue.coordinates.lng]}
          icon={icon}
        >
          <Popup>
            <div className="p-2">
              <h3 className="font-semibold text-lg">{synagogue.name}</h3>
              <p className="text-sm text-gray-600">{synagogue.address}</p>
              {synagogue.phone && (
                <p className="text-sm text-blue-600 mt-1">
                  <a href={`tel:${synagogue.phone}`}>{synagogue.phone}</a>
                </p>
              )}
              <div className="mt-2 text-sm">
                <p className="font-semibold">Shacharit:</p>
                <p>{synagogue.prayerTimes.shacharit.join(', ')}</p>
              </div>
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}