import type { Synagogue } from '../types';

export const synagogues: Synagogue[] = [
  {
    id: '1',
    name: 'Grande Synagogue de Marseille',
    address: '117 Rue Breteuil, 13006 Marseille',
    coordinates: {
      lat: 43.2889,
      lng: 5.3737,
    },
    phone: '04 91 37 49 64',
    hasAccessCode: false,
    prayerTimes: {
      shacharit: ['07:00', '08:30'],
      mincha: ['14:00', '19:30'],
      arvit: ['20:30', '21:30'],
    },
    rite: 'sephardi',
    description: 'La plus grande synagogue de Marseille, construite en 1864.',
    imageUrl: 'https://images.unsplash.com/photo-1585208798174-6cedd86e019a?auto=format&fit=crop&w=800',
  },
  {
    id: '2',
    name: 'Synagogue du Panier',
    address: '23 Rue de l\'Évêché, 13002 Marseille',
    coordinates: {
      lat: 43.2988,
      lng: 5.3692,
    },
    hasAccessCode: true,
    prayerTimes: {
      shacharit: ['07:30'],
      mincha: ['19:00'],
      arvit: ['20:00'],
    },
    rite: 'sephardi',
    description: 'Synagogue historique du quartier du Panier.',
  },
  {
    id: '3',
    name: 'Beth Habad Marseille',
    address: '22 Rue Sainte, 13001 Marseille',
    coordinates: {
      lat: 43.2945,
      lng: 5.3724,
    },
    phone: '04 91 37 30 96',
    hasAccessCode: false,
    prayerTimes: {
      shacharit: ['07:00', '08:00', '09:00'],
      mincha: ['13:30', '19:00'],
      arvit: ['20:15', '21:00'],
    },
    rite: 'ashkenazi',
    description: 'Centre Loubavitch de Marseille, offrant des cours et activités communautaires.',
    imageUrl: 'https://images.unsplash.com/photo-1592564630984-7410f94db184?auto=format&fit=crop&w=800',
  },
  {
    id: '4',
    name: 'Synagogue Or Thora',
    address: '89 Boulevard Barry, 13013 Marseille',
    coordinates: {
      lat: 43.3219,
      lng: 5.4019,
    },
    phone: '04 91 66 88 66',
    hasAccessCode: false,
    prayerTimes: {
      shacharit: ['06:45', '08:00'],
      mincha: ['13:45', '19:15'],
      arvit: ['20:00'],
    },
    rite: 'sephardi',
    description: 'Centre communautaire et lieu d\'étude.',
  },
  {
    id: '5',
    name: 'Synagogue Michkenot Yaacov',
    address: '116 Rue Dragon, 13006 Marseille',
    coordinates: {
      lat: 43.2876,
      lng: 5.3789,
    },
    hasAccessCode: true,
    prayerTimes: {
      shacharit: ['07:15'],
      mincha: ['19:00'],
      arvit: ['20:30'],
    },
    rite: 'ashkenazi',
    description: 'Synagogue traditionnelle ashkénaze.',
  },
  {
    id: '6',
    name: 'Centre Communautaire Edmond Fleg',
    address: '4 Impasse Dragon, 13006 Marseille',
    coordinates: {
      lat: 43.2882,
      lng: 5.3783,
    },
    phone: '04 91 37 42 01',
    hasAccessCode: false,
    prayerTimes: {
      shacharit: ['07:30', '08:30'],
      mincha: ['14:00', '19:00'],
      arvit: ['20:00', '21:00'],
    },
    rite: 'sephardi',
    description: 'Centre culturel et synagogue avec de nombreuses activités communautaires.',
    imageUrl: 'https://images.unsplash.com/photo-1555952238-7d76baaf1782?auto=format&fit=crop&w=800',
  },
  {
    id: '7',
    name: 'Synagogue Beth El',
    address: '13 Rue Sénac, 13001 Marseille',
    coordinates: {
      lat: 43.2967,
      lng: 5.3831,
    },
    hasAccessCode: true,
    prayerTimes: {
      shacharit: ['07:00'],
      mincha: ['19:30'],
      arvit: ['20:45'],
    },
    rite: 'sephardi',
    description: 'Petite synagogue de quartier avec une communauté chaleureuse.',
  },
  {
    id: '8',
    name: 'Synagogue Neve Chalom',
    address: '47 Boulevard Rabatau, 13008 Marseille',
    coordinates: {
      lat: 43.2741,
      lng: 5.3892,
    },
    phone: '04 91 77 96 72',
    hasAccessCode: false,
    prayerTimes: {
      shacharit: ['06:30', '07:30', '08:30'],
      mincha: ['13:30', '18:45'],
      arvit: ['19:45', '21:00'],
    },
    rite: 'sephardi',
    description: 'Grande synagogue moderne avec une communauté active.',
    imageUrl: 'https://images.unsplash.com/photo-1576353646362-80fa3c568b3b?auto=format&fit=crop&w=800',
  }
];