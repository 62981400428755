import React from 'react';
import { Heart, MapPin, Phone, Info } from 'lucide-react';
import { useStore } from '../../store/useStore';
import type { Synagogue } from '../../types';
import { PrayerTimes } from './PrayerTimes';
import { NextPrayer } from './NextPrayer';
import { useGeolocation } from '../../hooks/useGeolocation';
import { calculateDistance } from '../../utils/distance';

interface SynagogueListProps {
  synagogues: Synagogue[];
}

export function SynagogueList({ synagogues }: SynagogueListProps) {
  const { user, toggleFavorite } = useStore();
  const { coordinates } = useGeolocation();

  return (
    <div className="space-y-6">
      {synagogues.map((synagogue) => (
        <div
          key={synagogue.id}
          className="card p-6 animate-slide-up"
        >
          <div className="md:flex gap-6">
            {synagogue.imageUrl && (
              <div className="md:w-1/3 mb-4 md:mb-0">
                <img
                  src={synagogue.imageUrl}
                  alt={synagogue.name}
                  className="w-full h-48 object-cover rounded-lg"
                />
              </div>
            )}
            <div className="flex-1">
              <div className="flex justify-between items-start">
                <div>
                  <h2 className="text-xl font-semibold text-gray-900 flex items-center gap-2">
                    {synagogue.name}
                    <span className="text-sm font-normal text-gray-500 px-2 py-1 bg-gray-100 rounded-full">
                      {synagogue.rite === 'sephardi' ? 'Séfarade' : 'Ashkénaze'}
                    </span>
                  </h2>
                  <div className="flex items-center text-gray-600 mt-2">
                    <MapPin className="w-4 h-4 mr-1 flex-shrink-0" />
                    <p>{synagogue.address}</p>
                  </div>
                  {coordinates && (
                    <p className="text-sm text-gray-500 mt-1 flex items-center">
                      <Info className="w-4 h-4 mr-1" />
                      {calculateDistance(
                        coordinates.lat,
                        coordinates.lng,
                        synagogue.coordinates.lat,
                        synagogue.coordinates.lng
                      ).toFixed(1)}{' '}
                      km
                    </p>
                  )}
                  {synagogue.phone && (
                    <a
                      href={`tel:${synagogue.phone}`}
                      className="text-blue-600 hover:text-blue-800 mt-2 flex items-center"
                    >
                      <Phone className="w-4 h-4 mr-1" />
                      {synagogue.phone}
                    </a>
                  )}
                </div>
                {user && (
                  <button
                    onClick={() => toggleFavorite(synagogue.id)}
                    className="text-gray-400 hover:text-red-500 transition-colors p-2 hover:bg-red-50 rounded-full"
                    aria-label={
                      user.favorites.includes(synagogue.id)
                        ? 'Retirer des favoris'
                        : 'Ajouter aux favoris'
                    }
                  >
                    <Heart
                      className={`w-6 h-6 ${
                        user.favorites.includes(synagogue.id)
                          ? 'fill-red-500 text-red-500'
                          : ''
                      }`}
                    />
                  </button>
                )}
              </div>

              {synagogue.description && (
                <p className="text-gray-600 mt-3 text-sm">{synagogue.description}</p>
              )}

              <div className="mt-4">
                <NextPrayer times={synagogue.prayerTimes} />
              </div>

              <div className="mt-4 bg-gray-50 rounded-lg p-4">
                <PrayerTimes times={synagogue.prayerTimes} />
              </div>

              {synagogue.hasAccessCode && synagogue.phone && (
                <div className="mt-4 p-4 bg-blue-50 rounded-lg border border-blue-100">
                  <p className="text-sm text-blue-800">
                    Cette synagogue nécessite un code d'accès. Veuillez contacter le
                    responsable au{' '}
                    <a
                      href={`tel:${synagogue.phone}`}
                      className="font-medium hover:underline"
                    >
                      {synagogue.phone}
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}