import React, { Component, ErrorInfo, ReactNode } from 'react';
import { AlertCircle } from 'lucide-react';
import { Card } from './Card';
import { Container } from './Container';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Container size="sm" className="py-12">
          <Card className="p-6">
            <div className="flex items-start space-x-3">
              <AlertCircle className="w-6 h-6 text-red-500 flex-shrink-0" />
              <div>
                <h2 className="text-lg font-semibold text-gray-900">
                  Une erreur est survenue
                </h2>
                <p className="mt-1 text-sm text-gray-600">
                  {this.state.error?.message || 'Une erreur inattendue est survenue.'}
                </p>
                <button
                  onClick={() => window.location.reload()}
                  className="mt-4 text-sm text-blue-600 hover:text-blue-800"
                >
                  Rafraîchir la page
                </button>
              </div>
            </div>
          </Card>
        </Container>
      );
    }

    return this.props.children;
  }
}