import { useState, useEffect } from 'react';
import { 
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  updateProfile
} from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import { useStore } from '../store/useStore';
import { useNavigate } from 'react-router-dom';
import type { User } from '../types';

export function useAuth() {
  const [loading, setLoading] = useState(true);
  const { setUser } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
          const userData = userDoc.data() as Omit<User, 'id'> | undefined;

          const userState: User = {
            id: firebaseUser.uid,
            username: userData?.username || firebaseUser.displayName || '',
            email: firebaseUser.email || '',
            favorites: userData?.favorites || [],
            notificationPreferences: userData?.notificationPreferences || {
              prayers: [],
              reminderMinutes: 30,
              pushEnabled: false,
            },
          };

          setUser(userState);
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUser(null);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [setUser]);

  const signUp = async (email: string, password: string, username: string) => {
    try {
      const { user: firebaseUser } = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(firebaseUser, { displayName: username });

      const userData: Omit<User, 'id'> = {
        username,
        email,
        favorites: [],
        notificationPreferences: {
          prayers: [],
          reminderMinutes: 30,
          pushEnabled: false,
        },
      };

      await setDoc(doc(db, 'users', firebaseUser.uid), userData);

      setUser({
        id: firebaseUser.uid,
        ...userData,
      });

      return { success: true };
    } catch (error: any) {
      console.error('Error during sign up:', error);
      throw new Error(error?.message || 'Une erreur est survenue lors de l\'inscription');
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      const { user: firebaseUser } = await signInWithEmailAndPassword(auth, email, password);
      const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
      const userData = userDoc.data() as Omit<User, 'id'> | undefined;

      const userState: User = {
        id: firebaseUser.uid,
        username: userData?.username || firebaseUser.displayName || '',
        email: firebaseUser.email || '',
        favorites: userData?.favorites || [],
        notificationPreferences: userData?.notificationPreferences || {
          prayers: [],
          reminderMinutes: 30,
          pushEnabled: false,
        },
      };

      setUser(userState);
      return { success: true };
    } catch (error: any) {
      console.error('Error during sign in:', error);
      throw new Error(error?.message || 'Email ou mot de passe incorrect');
    }
  };

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
      setUser(null);
      navigate('/', { replace: true }); // Use replace to prevent back navigation
    } catch (error) {
      console.error('Error during sign out:', error);
      throw error;
    }
  };

  return {
    loading,
    signUp,
    signIn,
    signOut,
  };
}