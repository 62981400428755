import { shuffle } from '../utils/array';

export interface AuthQuestion {
  id: number;
  question: string;
  options: string[];
  correctAnswer: string;
}

export const authQuestions: AuthQuestion[] = [
  {
    id: 1,
    question: "Quel est le jour du Shabbat?",
    options: ["Vendredi", "Samedi", "Dimanche"],
    correctAnswer: "Samedi"
  },
  {
    id: 2,
    question: "Quelle est la première fête du calendrier juif?",
    options: ["Pessah", "Rosh Hashana", "Souccot"],
    correctAnswer: "Rosh Hashana"
  },
  {
    id: 3,
    question: "Combien de branches compte une Menorah de Hanoukka?",
    options: ["7", "8", "9"],
    correctAnswer: "9"
  },
  {
    id: 4,
    question: "Quel est le nom du Nouvel An des arbres?",
    options: ["Tou Bichvat", "Lag Baomer", "Pourim"],
    correctAnswer: "Tou Bichvat"
  },
  {
    id: 5,
    question: "Quelle est la dernière fête du cycle annuel?",
    options: ["Simhat Torah", "Souccot", "Shavouot"],
    correctAnswer: "Simhat Torah"
  },
  {
    id: 6,
    question: "Quel est le jeûne le plus important de l'année?",
    options: ["Kippour", "Tisha Beav", "17 Tamouz"],
    correctAnswer: "Kippour"
  },
  {
    id: 7,
    question: "Combien de jours dure la fête de Pessah?",
    options: ["7", "8", "9"],
    correctAnswer: "8"
  },
  {
    id: 8,
    question: "Quel est le nom de la prière du soir?",
    options: ["Shaharit", "Minha", "Arvit"],
    correctAnswer: "Arvit"
  }
];

export function getRandomQuestions(count: number = 3): AuthQuestion[] {
  return shuffle([...authQuestions]).slice(0, count);
}