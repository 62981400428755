import React from 'react';
import { Clock } from 'lucide-react';
import { getNextPrayerTime, formatRemainingTime } from '../../utils/time';
import type { Synagogue } from '../../types';

interface NextPrayerProps {
  times: Synagogue['prayerTimes'];
}

const prayerNames = {
  shacharit: 'Chaharit',
  mincha: 'Minha',
  arvit: 'Arvit',
};

export function NextPrayer({ times }: NextPrayerProps) {
  const nextPrayer = getNextPrayerTime(times);

  if (!nextPrayer) {
    return null;
  }

  return (
    <div className="flex items-center space-x-2 text-blue-600">
      <Clock className="w-4 h-4" />
      <span className="text-sm">
        Prochain office : {prayerNames[nextPrayer.prayer]} dans{' '}
        {formatRemainingTime(nextPrayer.remainingTime)} ({nextPrayer.time})
      </span>
    </div>
  );
}