import { useState, useCallback } from 'react';

export function useNotifications() {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [error, setError] = useState<string | null>(
    'Les notifications push ne sont pas disponibles dans cet environnement.'
  );

  const subscribe = useCallback(async () => {
    setError('Les notifications push ne sont pas disponibles dans cet environnement.');
    throw new Error('Les notifications push ne sont pas disponibles dans cet environnement.');
  }, []);

  const unsubscribe = useCallback(async () => {
    setError('Les notifications push ne sont pas disponibles dans cet environnement.');
    throw new Error('Les notifications push ne sont pas disponibles dans cet environnement.');
  }, []);

  return {
    isSubscribed,
    error,
    subscribe,
    unsubscribe
  };
}