import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Navigation } from './Navigation';
import { LoadingPage } from './shared/LoadingPage';
import { Container } from './shared/Container';

export function Layout() {
  return (
    <div className="min-h-screen flex flex-col">
      <Navigation />
      <main className="flex-1 py-8 animate-fade-in">
        <Suspense fallback={<LoadingPage />}>
          <Container>
            <Outlet />
          </Container>
        </Suspense>
      </main>
      <footer className="bg-white/80 backdrop-blur-sm border-t mt-auto py-6">
        <Container>
          <div className="text-center">
            <p className="text-gray-600 font-medium">
              © {new Date().getFullYear()} Tefilati - Guide des Synagogues de Marseille
            </p>
            <p className="text-sm text-gray-500 mt-1">
              Tous droits réservés
            </p>
          </div>
        </Container>
      </footer>
    </div>
  );
}