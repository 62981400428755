import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Layout } from './components/Layout';
import { Home } from './pages/Home';
import { Map } from './pages/Map';
import { Schedule } from './pages/Schedule';
import { Favorites } from './pages/Favorites';
import { Notifications } from './pages/Notifications';
import { Settings } from './pages/Settings';
import { AuthCheck } from './components/AuthCheck';
import { ErrorBoundary } from './components/shared/ErrorBoundary';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      retry: 1,
    },
  },
});

function App() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route
                path="carte"
                element={
                  <AuthCheck>
                    <Map />
                  </AuthCheck>
                }
              />
              <Route
                path="horaires"
                element={
                  <AuthCheck>
                    <Schedule />
                  </AuthCheck>
                }
              />
              <Route
                path="favoris"
                element={
                  <AuthCheck>
                    <Favorites />
                  </AuthCheck>
                }
              />
              <Route
                path="notifications"
                element={
                  <AuthCheck>
                    <Notifications />
                  </AuthCheck>
                }
              />
              <Route
                path="parametres"
                element={
                  <AuthCheck>
                    <Settings />
                  </AuthCheck>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;