import React from 'react';
import { LoadingSpinner } from './LoadingSpinner';
import { Container } from './Container';

export function LoadingPage() {
  return (
    <Container className="flex items-center justify-center min-h-[60vh]">
      <div className="text-center">
        <LoadingSpinner size="lg" className="mx-auto mb-4 text-blue-600" />
        <p className="text-gray-600">Chargement...</p>
      </div>
    </Container>
  );
}