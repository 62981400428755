import React, { useState } from 'react';
import { X, AlertCircle, Mail, Lock, User } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { getRandomQuestions } from '../data/authQuestions';
import { Button } from './shared/Button';
import { Input } from './shared/Input';
import { Alert } from './shared/Alert';

interface AuthModalProps {
  onClose: () => void;
  initialMode?: 'login' | 'register';
}

export function AuthModal({ onClose, initialMode = 'login' }: AuthModalProps) {
  const [mode, setMode] = useState<'login' | 'register'>(initialMode);
  const [step, setStep] = useState<'credentials' | 'questions' | 'success'>('credentials');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { signIn, signUp } = useAuth();

  const questions = React.useMemo(() => getRandomQuestions(3), []);
  const currentQuestion = questions[currentQuestionIndex];

  const handleCredentialsSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      if (mode === 'register') {
        if (!email || !username || !password) {
          throw new Error('Veuillez remplir tous les champs');
        }
        if (!email.includes('@')) {
          throw new Error('Email invalide');
        }
        if (password.length < 6) {
          throw new Error('Le mot de passe doit contenir au moins 6 caractères');
        }
        setStep('questions');
      } else {
        await signIn(email, password);
        onClose();
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Une erreur est survenue');
    } finally {
      setLoading(false);
    }
  };

  const handleAnswer = async (answer: string) => {
    const newAnswers = [...answers, answer];
    setAnswers(newAnswers);

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      const allCorrect = questions.every(
        (q, index) => newAnswers[index] === q.correctAnswer
      );

      if (allCorrect) {
        setLoading(true);
        try {
          const result = await signUp(email, password, username);
          setSuccess(result.message);
          setStep('success');
        } catch (err) {
          setError(err instanceof Error ? err.message : 'Une erreur est survenue');
          setStep('credentials');
          setAnswers([]);
          setCurrentQuestionIndex(0);
        } finally {
          setLoading(false);
        }
      } else {
        setError('Réponses incorrectes. Veuillez réessayer.');
        setStep('credentials');
        setAnswers([]);
        setCurrentQuestionIndex(0);
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-2xl p-6 max-w-md w-full animate-slide-up">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-900">
            {mode === 'login' ? 'Connexion' : 'Inscription'}
          </h2>
          <Button
            variant="ghost"
            size="sm"
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600"
          >
            <X className="w-6 h-6" />
          </Button>
        </div>

        {error && (
          <Alert type="error" className="mb-4">
            {error}
          </Alert>
        )}

        {success && (
          <Alert type="success" className="mb-4">
            {success}
          </Alert>
        )}

        {step === 'credentials' ? (
          <form onSubmit={handleCredentialsSubmit} className="space-y-4">
            {mode === 'register' && (
              <Input
                id="username"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Votre nom d'utilisateur"
                label="Nom d'utilisateur"
                icon={<User className="w-4 h-4" />}
                autoComplete="username"
              />
            )}
            
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="votre@email.com"
              label="Email"
              icon={<Mail className="w-4 h-4" />}
              autoComplete="email"
            />

            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="••••••••"
              label="Mot de passe"
              icon={<Lock className="w-4 h-4" />}
              autoComplete={mode === 'login' ? 'current-password' : 'new-password'}
            />

            <div className="flex flex-col gap-3 pt-2">
              <Button
                type="submit"
                variant="gradient"
                isLoading={loading}
                fullWidth
              >
                {mode === 'login' ? 'Se connecter' : 'Continuer'}
              </Button>
              <Button
                type="button"
                variant="ghost"
                onClick={() => setMode(mode === 'login' ? 'register' : 'login')}
                className="text-sm text-gray-600 hover:text-blue-600"
                disabled={loading}
              >
                {mode === 'login'
                  ? "Pas encore de compte ? S'inscrire"
                  : 'Déjà un compte ? Se connecter'}
              </Button>
            </div>
          </form>
        ) : step === 'questions' ? (
          <div>
            <p className="text-gray-600 mb-6">
              Pour finaliser votre inscription, veuillez répondre à ces questions :
            </p>

            <div className="mb-6">
              <p className="font-medium mb-4">{currentQuestion.question}</p>
              <div className="space-y-2">
                {currentQuestion.options.map((option) => (
                  <Button
                    key={option}
                    onClick={() => handleAnswer(option)}
                    variant="outline"
                    fullWidth
                    className="justify-start"
                    disabled={loading}
                  >
                    {option}
                  </Button>
                ))}
              </div>
            </div>

            <div className="flex justify-between items-center text-sm text-gray-500">
              <span>
                Question {currentQuestionIndex + 1} sur {questions.length}
              </span>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => {
                  setStep('credentials');
                  setAnswers([]);
                  setCurrentQuestionIndex(0);
                }}
                disabled={loading}
              >
                Annuler
              </Button>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <p className="text-gray-600 mb-4">
              {success}
            </p>
            <Button
              variant="gradient"
              onClick={onClose}
              fullWidth
            >
              Fermer
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}