import React from 'react';
import { MapPin, Bell, Heart, LogIn, UserPlus } from 'lucide-react';
import { AuthModal } from '../components/AuthModal';
import { useStore } from '../store/useStore';
import { useSearchParams } from 'react-router-dom';
import { Button } from '../components/shared/Button';
import { Card } from '../components/shared/Card';
import { Container } from '../components/shared/Container';
import { usePageTitle } from '../hooks/usePageTitle';

export function Home() {
  usePageTitle('Accueil');
  const [searchParams] = useSearchParams();
  const [showAuthModal, setShowAuthModal] = React.useState(false);
  const [authMode, setAuthMode] = React.useState<'login' | 'register'>('login');
  const { isAuthenticated } = useStore();

  React.useEffect(() => {
    const authParam = searchParams.get('auth');
    if (authParam === 'login' || authParam === 'register') {
      setAuthMode(authParam);
      setShowAuthModal(true);
    }
  }, [searchParams]);

  const handleShowAuth = (mode: 'login' | 'register') => {
    setAuthMode(mode);
    setShowAuthModal(true);
  };

  if (isAuthenticated) {
    return (
      <div className="text-center">
        <h1 className="heading-primary mb-6">Bienvenue sur votre espace</h1>
        <p className="text-gray-600 mb-8">
          Vous pouvez maintenant accéder à toutes les fonctionnalités de l'application.
        </p>
        <div className="grid md:grid-cols-3 gap-6">
          <Card className="p-6">
            <MapPin className="w-8 h-8 text-blue-600 mx-auto mb-4" />
            <h2 className="text-xl font-semibold mb-2">Localisation</h2>
            <p className="text-gray-600">
              Trouvez les synagogues proches de vous
            </p>
          </Card>
          <Card className="p-6">
            <Bell className="w-8 h-8 text-blue-600 mx-auto mb-4" />
            <h2 className="text-xl font-semibold mb-2">Notifications</h2>
            <p className="text-gray-600">
              Recevez des rappels pour les offices
            </p>
          </Card>
          <Card className="p-6">
            <Heart className="w-8 h-8 text-blue-600 mx-auto mb-4" />
            <h2 className="text-xl font-semibold mb-2">Favoris</h2>
            <p className="text-gray-600">
              Enregistrez vos synagogues préférées
            </p>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="text-center mb-12">
        <h1 className="heading-primary mb-6">Guide des Synagogues de Marseille</h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
          Trouvez facilement les horaires de prières et restez connecté à votre communauté.
        </p>
      </div>

      <div className="grid md:grid-cols-3 gap-8 mb-12">
        <Card className="p-6 text-center">
          <MapPin className="w-8 h-8 text-blue-600 mx-auto mb-4" />
          <h2 className="text-lg font-semibold mb-2">Localisation Facile</h2>
          <p className="text-gray-600">
            Trouvez les synagogues les plus proches de vous avec notre carte interactive
          </p>
        </Card>

        <Card className="p-6 text-center">
          <Bell className="w-8 h-8 text-blue-600 mx-auto mb-4" />
          <h2 className="text-lg font-semibold mb-2">Notifications</h2>
          <p className="text-gray-600">
            Recevez des rappels pour ne jamais manquer un office
          </p>
        </Card>

        <Card className="p-6 text-center">
          <Heart className="w-8 h-8 text-blue-600 mx-auto mb-4" />
          <h2 className="text-lg font-semibold mb-2">Favoris</h2>
          <p className="text-gray-600">
            Enregistrez vos synagogues préférées pour un accès rapide
          </p>
        </Card>
      </div>

      <Card className="p-8 text-center">
        <h2 className="text-2xl font-bold mb-4">Pourquoi créer un compte ?</h2>
        <div className="space-y-4 text-gray-600 mb-8">
          <p>✓ Accédez à la carte interactive des synagogues</p>
          <p>✓ Consultez les horaires de prières en temps réel</p>
          <p>✓ Recevez des notifications personnalisées</p>
          <p>✓ Créez votre liste de synagogues favorites</p>
        </div>
        <div className="flex justify-center gap-4">
          <Button
            variant="secondary"
            size="lg"
            onClick={() => handleShowAuth('login')}
            className="group"
          >
            <LogIn className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            Se connecter
          </Button>
          <Button
            variant="gradient"
            size="lg"
            onClick={() => handleShowAuth('register')}
            className="group"
          >
            <UserPlus className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            S'inscrire
          </Button>
        </div>
      </Card>

      {showAuthModal && !isAuthenticated && (
        <AuthModal
          onClose={() => setShowAuthModal(false)}
          initialMode={authMode}
        />
      )}
    </div>
  );
}