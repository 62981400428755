import { format, parse, isAfter, isBefore } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { Synagogue } from '../types';

export function getNextPrayerTime(prayerTimes: Synagogue['prayerTimes']): {
  prayer: 'shacharit' | 'mincha' | 'arvit';
  time: string;
  remainingTime: number;
} | null {
  const now = new Date();
  const today = format(now, 'yyyy-MM-dd');
  
  const allTimes = [
    ...prayerTimes.shacharit.map(time => ({ prayer: 'shacharit' as const, time })),
    ...prayerTimes.mincha.map(time => ({ prayer: 'mincha' as const, time })),
    ...prayerTimes.arvit.map(time => ({ prayer: 'arvit' as const, time })),
  ];

  const futureTimes = allTimes
    .map(({ prayer, time }) => {
      const dateTime = parse(`${today} ${time}`, 'yyyy-MM-dd HH:mm', new Date());
      return {
        prayer,
        time,
        dateTime,
        remainingTime: dateTime.getTime() - now.getTime(),
      };
    })
    .filter(({ dateTime }) => isAfter(dateTime, now))
    .sort((a, b) => a.remainingTime - b.remainingTime);

  return futureTimes.length > 0
    ? {
        prayer: futureTimes[0].prayer,
        time: futureTimes[0].time,
        remainingTime: futureTimes[0].remainingTime,
      }
    : null;
}

export function formatRemainingTime(ms: number): string {
  const minutes = Math.floor(ms / 1000 / 60);
  const hours = Math.floor(minutes / 60);
  
  if (hours > 0) {
    return `${hours}h${minutes % 60 > 0 ? ` ${minutes % 60}min` : ''}`;
  }
  return `${minutes}min`;
}