import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyDfPh3rVM8o9ddmLClUD8oghKTkAzf8okQ",
  authDomain: "tefilati.com",
  projectId: "tefilati-9ceda",
  storageBucket: "tefilati-9ceda.appspot.com",
  messagingSenderId: "923258284896",
  appId: "1:923258284896:web:401d0aa82fd5d2eb334c05",
  measurementId: "G-KFPX6HKJKJ"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app, 'europe-west1');

if (typeof window !== 'undefined') {
  enableIndexedDbPersistence(db).catch((err) => {
    if (err.code === 'failed-precondition') {
      console.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
    } else if (err.code === 'unimplemented') {
      console.warn('The current browser doesn\'t support all of the features required to enable persistence');
    }
  });
}