import React, { Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useStore } from '../store/useStore';
import { LoadingPage } from './shared/LoadingPage';

interface AuthCheckProps {
  children: React.ReactNode;
}

export function AuthCheck({ children }: AuthCheckProps) {
  const { isAuthenticated } = useStore();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to={`/?auth=login&redirect=${location.pathname}`} replace />;
  }

  return <Suspense fallback={<LoadingPage />}>{children}</Suspense>;
}