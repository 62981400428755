import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import type { User } from '../types';

interface Store {
  user: User | null;
  isAuthenticated: boolean;
  setUser: (user: User | null) => void;
  toggleFavorite: (synagogueId: string) => Promise<void>;
  updateNotificationPreferences: (preferences: User['notificationPreferences']) => Promise<void>;
  updateProfile: (username: string) => Promise<void>;
}

export const useStore = create<Store>()(
  persist(
    (set, get) => ({
      user: null,
      isAuthenticated: false,
      setUser: (user) => set({ user, isAuthenticated: !!user }),
      toggleFavorite: async (synagogueId) => {
        const state = get();
        if (!state.user) return;

        try {
          const favorites = state.user.favorites.includes(synagogueId)
            ? state.user.favorites.filter((id) => id !== synagogueId)
            : [...state.user.favorites, synagogueId];

          const userRef = doc(db, 'users', state.user.id);
          await setDoc(userRef, { favorites }, { merge: true });

          set({
            user: {
              ...state.user,
              favorites,
            },
          });
        } catch (error) {
          console.error('Error toggling favorite:', error);
          throw error;
        }
      },
      updateNotificationPreferences: async (preferences) => {
        const state = get();
        if (!state.user) return;

        try {
          const userRef = doc(db, 'users', state.user.id);
          await setDoc(userRef, { notificationPreferences: preferences }, { merge: true });

          set({
            user: {
              ...state.user,
              notificationPreferences: preferences,
            },
          });
        } catch (error) {
          console.error('Error updating notification preferences:', error);
          throw error;
        }
      },
      updateProfile: async (username) => {
        const state = get();
        if (!state.user) return;

        try {
          const userRef = doc(db, 'users', state.user.id);
          await setDoc(userRef, { username }, { merge: true });

          set({
            user: {
              ...state.user,
              username,
            },
          });
        } catch (error) {
          console.error('Error updating profile:', error);
          throw error;
        }
      },
    }),
    {
      name: 'tefilati-storage',
      partialize: (state) => ({
        user: state.user,
        isAuthenticated: state.isAuthenticated,
      }),
    }
  )
);